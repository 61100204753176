/* Initial header style with transparent background */
.header-transparent #nav-part {
    background-color: transparent;
    transition: background-color 0.3s ease, top 0.3s ease;
}

/* Header style when scrolled, with a solid background */
.header-scrolled #nav-part {
    background-color: #200449;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, top 0.3s ease;
}


.contactus-inner {
    padding: 9px 9px;
    width: 100px;
    margin-left: 20px;
    text-decoration: none;
    position: relative;
    display: inline-block;
    color: white;
    font-size: 20px;
    vertical-align: top;
    text-align: center;
    letter-spacing: 1px;
    line-height: 24px;
    color: #fefefe;
    font-weight: 400;
    font-family: "Anton";
    padding: 13px 15px;
    border-radius: 35px;
    text-transform: capitalize;
    width: 130px;
    -webkit-transition: all ease 0.5s;
    -moz-transition: all ease 0.5s;
    -ms-transition: all ease 0.5s;
    -o-transition: all ease 0.5s;
    transition: all ease 0.5s;
    box-shadow: 0px 0px 2px 2px rgba(247, 172, 4, 0.8);
    text-shadow: 5px 4px 0px #efa500;
    background: linear-gradient(to bottom, #ffd679 30%, #f7ac03 70%);
    filter: drop-shadow(0 0px 20px rgba(101, 40, 193, 0.5));
}

.contactus-inner:hover {
    color: white;
    box-shadow: 0px 0px 1px 2px rgba(152, 51, 134, 0.9);
    filter: drop-shadow(0 0px 20px rgba(152, 51, 134, 0.6));
    background: linear-gradient(to bottom, #e673cf 10%, #9a368a 90%);
    text-shadow: 5px 4px 0px #943184;
}

@media (max-width: 990px) {
    .contactus-inner {
    /* padding: 9px 9px; */
        width: 100px;
        margin-left: 20px;
        text-decoration: none;
        position: relative;
        display: inline-block;
        color: white;
        font-size: 22px;
        vertical-align: top;
        text-align: center;
        letter-spacing: 1px;
        line-height: 17px;
        color: #fefefe;
        font-weight: 400;
        font-family: "Anton";
        padding: 13px 9px;
        border-radius: 35px;
        text-transform: capitalize;
        width: 130px;
        -webkit-transition: all ease 0.5s;
        -moz-transition: all ease 0.5s;
        -ms-transition: all ease 0.5s;
        -o-transition: all ease 0.5s;
        transition: all ease 0.5s;
        box-shadow: 0px 0px 2px 2px rgba(247, 172, 4, 0.8);
        text-shadow: 5px 4px 0px #efa500;
        background: linear-gradient(to bottom, #ffd679 30%, #f7ac03 70%);
        filter: drop-shadow(0 0px 20px rgba(101, 40, 193, 0.5));
    }
}
@media (min-width: 759px) {
    .resnav-inner {
        display: none;
        /* width: 50px; */
    }
}
