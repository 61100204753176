/*
------------------

Project:        Casine - Casino and gambling HTML5 Template
Version:        1.0
Last change:    11/ 05 /2020
Primary use:    Casino and gambling. 
Author:         Ingenious_team
------------------

----------------
Table of Content
----------------

1.  preloader
2.  Header Part

    2.1. nav part
    2.2. banner part

3.  BE IN CONTROL Part
4.  HOW TO START Part
5.  RELATED OTHER GAMES Part
6.  UNLOCK FREE SPIN part
7.  CASINO JACKPOTS Part
8. FREQUENTLY ASK QUESTIONS part
9. CONTACT part
10. FOOTER part

---- other pages ----

11. About us page
12. GAMES page
13. Tournaments page
14. FAQ page
15. CONTACT page
16. 404 page

*/

@import url('https://fonts.googleapis.com/css?family=Anton:300,400,600,800|Nunito:300,400,600,800');

* {
    margin: 0;
    padding: 0;
    outline: 0;
}

ul,
ol {
    list-style: none;
}

a {
    text-decoration: none;
}

input:focus {
    outline: none !important;
    box-shadow: 0 0 10px transparent !important;
}

a:focus,
a:hover {
    text-decoration: none;
    border-color: transparent;
    outline: 0;
    color: transparent;
}

img {
    border: 0;
}

.clr {
    clear: both;
}

h1,
h2,
h3,
h4,
h5,
h6,
ol,
ul,
p,
address,
strong,
i {
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: normal;
}

body {
    overflow-x: hidden;
}

#loading,
.load-circle:before {
    bottom: 0
}

.contact-page,
.contact,
.about-page,
.banner-inner,
.jackpots,
.free-spin,
.project-img,
.how-start,
.control,
.faq,
.contact-us {
    overflow: hidden;
}

/*
==========================================
    Preloader
==========================================
*/

.preloader {
    overflow: hidden;
    height: 100%;
    width: 100%;
    position: fixed;
    background: radial-gradient(#150035, #0b001b);
    top: 0;
    left: 0;
    z-index: 9999999;
}
.loader {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
    width: 175px;
    height: 100px;
}

.loader span {
    display: block;
    background: rgba(247, 172, 4, 1);
    width: 7px;
    height: 10%;
    border-radius: 14px;
    margin-right: 5px;
    float: left;
    margin-top: 25%;
}

.loader span:last-child {
    margin-right: 0px;
}

.loader span:nth-child(1) {
    animation: load 2.5s 1.4s infinite linear;
}

.loader span:nth-child(2) {
    animation: load 2.5s 1.2s infinite linear;
}

.loader span:nth-child(3) {
    animation: load 2.5s 1s infinite linear;
}

.loader span:nth-child(4) {
    animation: load 2.5s 0.8s infinite linear;
}

.loader span:nth-child(5) {
    animation: load 2.5s 0.6s infinite linear;
}

.loader span:nth-child(6) {
    animation: load 2.5s 0.4s infinite linear;
}

.loader span:nth-child(7) {
    animation: load 2.5s 0.2s infinite linear;
}

.loader span:nth-child(8) {
    animation: load 2.5s 0s infinite linear;
}

.loader span:nth-child(9) {
    animation: load 2.5s 0.2s infinite linear;
}

.loader span:nth-child(10) {
    animation: load 2.5s 0.4s infinite linear;
}

.loader span:nth-child(11) {
    animation: load 2.5s 0.6s infinite linear;
}

.loader span:nth-child(12) {
    animation: load 2.5s 0.8s infinite linear;
}

.loader span:nth-child(13) {
    animation: load 2.5s 1s infinite linear;
}

.loader span:nth-child(14) {
    animation: load 2.5s 1.2s infinite linear;
}

.loader span:nth-child(15) {
    animation: load 2.5s 1.4s infinite linear;
}

@keyframes load {
    0% {
        background: rgba(247, 172, 4, 1);
        margin-top: 25%;
        height: 10%;
    }

    50% {
        background: linear-gradient(to bottom, rgba(223, 103, 198, 1) 0%, rgba(152, 51, 134, 1) 100%);
        height: 100%;
        margin-top: 0%;
    }

    100% {
        background: linear-gradient(to bottom, rgba(254, 215, 125, 1) 0%, rgba(247, 172, 4, 1) 100%);
        height: 10%;
        margin-top: 25%;
    }
}


/* == main css start from now == */


/* ============================
            common 
========================= */

.hidden {
    display: none;
}

.pr0 {
    padding-right: 0;
}

.pl0 {
    padding-left: 0;
}

.no-padding {
    padding-left: 0;
    padding-right: 0;
}

.mt0 {
    margin-top: 0 !important;
}

img {
    max-width: 100%
}

.section {
    padding-top: 80px;
}
::selection {
    background: #ffcd04;
    text-shadow: none;
}
p {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Nunito', sans-serif;
}

.back-light {
    background: #150035;
}

.back-dark {
    background: #0b001b;
}

.yellow-bg {
    background: -webkit-linear-gradient(to bottom, rgba(254, 215, 125, 1) 0%, rgba(247, 172, 4, 1) 100%) !important;
    background: -o-linear-gradient(to bottom, rgba(254, 215, 125, 1) 0%, rgba(247, 172, 4, 1) 100%);
    background: -moz-linear-gradient(to bottom, rgba(254, 215, 125, 1) 0%, rgba(247, 172, 4, 1) 100%);
    background: linear-gradient(to bottom, rgba(254, 215, 125, 1) 0%, rgba(247, 172, 4, 1) 100%);
}

.pink-bg {
    background: -webkit-linear-gradient(to bottom, rgba(223, 103, 198, 1) 0%, rgba(152, 51, 134, 1) 100%) !important;
    background: -o-linear-gradient(to bottom, rgba(223, 103, 198, 1) 0%, rgba(152, 51, 134, 1) 100%);
    background: -moz-linear-gradient(to bottom, rgba(223, 103, 198, 1) 0%, rgba(152, 51, 134, 1) 100%);
    background: linear-gradient(to bottom, rgba(223, 103, 198, 1) 0%, rgba(152, 51, 134, 1) 100%);
}

.pink-btn {
    background: rgba(152, 51, 134, 1);
}

.casino-btn a {
    position: relative;
    display: inline-block;
    font-size: 24px;
    vertical-align: top;
    text-align: center;
    letter-spacing: 1px;
    line-height: 24px;
    color: #fefefe;
    font-weight: 400;
    font-family: "Anton";
    padding: 13px 30px;
    border-radius: 35px;
    text-transform: capitalize;
    -webkit-transition: all ease 0.9s;
    -moz-transition: all ease 0.9s;
    -ms-transition: all ease 0.9s;
    -o-transition: all ease 0.9s;
    transition: all ease 0.9s;
    text-shadow: 5px 4px 0px rgba(0, 0, 0, .1);
    background: linear-gradient(to bottom, #ffd679 30%, #f7ac03 70%);
    filter: drop-shadow(0 0px 20px rgba(101, 40, 193, 0.5));
    box-shadow: 0px 0px 2px 1px rgba(247, 172, 4, 0.8);

}

.casino-btn a:hover {
    background: linear-gradient(to bottom, #e673cf 10%, #9a368a 90%);
    box-shadow: 0px 0px 3px 1px rgba(152, 51, 134, 0.4);
    text-shadow: 5px 3px 0px #943184;
    filter: drop-shadow(0 0px 20px rgba(152, 51, 134, 0.6));
}

.run-txt .casino-btn a:hover,
.run-txt .casino-btn a {
    filter: none;
}

.heading h2 {
    font-size: 36px;
    position: relative;
    line-height: 24px;
    font-weight: 400;
    font-family: 'Anton', sans-serif;
    background: -webkit-linear-gradient(#ffd370 0%, #f7ad07 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    z-index: 0;
    padding-top: 5px;
    padding-bottom: 30px;
    cursor: auto;
    letter-spacing: -1px;
}

.sub-heading h3 {
    font-size: 24px;
    position: relative;
    line-height: 24px;
    font-weight: 400;
    font-family: 'Anton', sans-serif;
    background: -webkit-linear-gradient(#ffd370 0%, #f7ad07 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    z-index: 0;
    padding-top: 5px;
    padding-bottom: 30px;
    cursor: auto;
}

@media only screen and (max-width: 767px) {
    .sub-heading h3{
        display: none;
    }
}

.border-effect1 {
    position: absolute;
    top: 44px;
    z-index: 99;
    width: 250px;
    left: -30px;
    animation: scan 8s infinite;
}

.border-effect2 {
    position: absolute;
    top: 44px;
    z-index: 99;
    width: 250px;
    right: 0px;
    animation: scan2 8s infinite;
}

.heading {
    margin-bottom: 60px;
}

.heading p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #666;
    font-family: "Arimo";
    z-index: 0;
    margin-top: 10px;
    cursor: auto;
}

/* ===============================
        Header Part css
==================================*/

.navbar {
    position: fixed;
    width: 100%;
    transition: all ease .9s;
    background: transparent;
}

.header_area {
    background: url(../images/other-nav-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 90px;
    background-color: #17003a;
}

.navcss {
    background: -webkit-linear-gradient(to bottom, rgba(25, 0, 64, 1) 0%, rgba(38, 8, 82, 1) 100%) !important;
    background: -o-linear-gradient(to bottom, rgba(25, 0, 64, 1) 0%, rgba(38, 8, 82, 1) 100%);
    background: -moz-linear-gradient(to bottom, rgba(25, 0, 64, 1) 0%, rgba(38, 8, 82, 1) 100%);
    background: linear-gradient(to bottom, rgba(25, 0, 64, 1) 0%, rgba(38, 8, 82, 1) 100%);
    box-shadow: 0 0px 5px 0px rgba(0, 0, 0, .3);
    transition: all ease .9s;
}

.header-nav .navbar-brand {
    color: #fff;
    font-weight: 800;
    position: relative;
    font-size: 23px
}

.header-nav .navbar-brand img {
    width: 110px;
}

.header-nav .navbar-brand span {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
    bottom: 12px;
    right: -9px;
    float: left
}

.header-nav .navbar-nav .nav-item {
    position: relative;
}

.header-nav .m-nav {
    margin-right: 60px;
}

.header-nav .navbar-nav .nav-item a {
    position: relative;
    padding: 0;
    display: inline-block;
    font-size: 16px;
    vertical-align: top;
    color: #fefefe;
    font-weight: 400;
    text-transform: capitalize;
    font-family: "Ubuntu";
}

.header-nav .nav-sign {
    vertical-align: top;
    position: absolute;
    right: 0;
}

.nav-sign li:first-child {
    margin-right: 10px;
}

.nav-sign li a {
    position: relative;
    display: inline-block;
    font-size: 24px;
    vertical-align: top;
    text-align: center;
    letter-spacing: 1px;
    line-height: 24px;
    color: #fefefe;
    font-weight: 400;
    font-family: "Anton";
    padding: 13px 15px;
    border-radius: 35px;
    text-transform: capitalize;
    width: 130px;
    -webkit-transition: all ease 0.5s;
    -moz-transition: all ease 0.5s;
    -ms-transition: all ease 0.5s;
    -o-transition: all ease 0.5s;
    transition: all ease 0.5s;
    box-shadow: 0px 0px 2px 2px rgba(247, 172, 4, 0.8);
    text-shadow: 5px 4px 0px #efa500;
    background: linear-gradient(to bottom, #ffd679 30%, #f7ac03 70%);
    filter: drop-shadow(0 0px 20px rgba(101, 40, 193, 0.5));
}

.nav-sign li a:hover {
    box-shadow: 0px 0px 1px 2px rgba(152, 51, 134, 0.9);
    filter: drop-shadow(0 0px 20px rgba(152, 51, 134, 0.6));
    background: linear-gradient(to bottom, #e673cf 10%, #9a368a 90%);
    text-shadow: 5px 4px 0px #943184;
}

.navbar-toggler {
    border: none;
    padding: 10px 6px;
    outline: 0 !important;
}

.navbar-toggler span {
    display: block;
    width: 24px;
    height: 3px;
    border-radius: 4px;
}

.navbar-toggler span + span {
    margin-top: 4px;
    width: 18px;
}

.navbar-toggler span + span + span {
    width: 10px;
}

.dropdown-toggle::after {
    margin-left: 4px !important;
    vertical-align: .05em !important;
}

.login_menu li {
    padding: 0;
    float: left;
}

.navbar-collapse {
    padding: 14px 0;
}


/* dropdown menu css */
.dropdown-menu {
    padding: 14px 5px;
    width: 180px;
    top: 64px;
    background: linear-gradient(to top, rgba(25, 0, 64, 1) 0%, rgba(38, 8, 82, 1) 100%);
    min-width: 210px;
    box-shadow: 0 2px 3px 0px rgba(0, 0, 0, .2);
    border-radius: 0;
    border: none;
    -webkit-transition: all ease 0.4s;
    -moz-transition: all ease 0.4s;
    -ms-transition: all ease 0.4s;
    -o-transition: all ease 0.4s;

}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
    background: none;
}

.dropdown-menu > li {
    padding: 10px 10px;
}

.dropdown-menu > li > a {
    font-size: 18px;
    position: relative;
    line-height: 24px;
    font-family: "Nunito";
    color: #fefefe;
}

.dropdown-menu > li > a:focus,
.dropdown-menu > li:hover a {
    background: none;
    color: #e8a004 !important;
    font-weight: 600;
    transition: all .4s;
    -webkit-transition: all ease 0.4s;
    -moz-transition: all ease 0.4s;
    -ms-transition: all ease 0.4s;
    -o-transition: all ease 0.4s;
}

.dropdown-menu > li > a {
    color: #fff !important;
    padding: 10px 18px !important;
    text-align: left;
    margin: 0 !important;
    font-weight: 400 !important;
}

.header-nav .navbar-nav li .active span,
.header-nav .navbar-nav li a:hover span,
.header-nav .navbar-nav li a:focus span {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    transform: translateY(-100%);
}

.custom_nav ul > li > a:focus:after,
.custom_nav ul > li.active > a:after,
.custom_nav ul > li:hover > a:after {
    opacity: 1;
}

@media(min-width:768px) {
    .dropdown-menu {
        display: block;
        visibility: hidden;
        opacity: 0;
        top: 110%;

    }

    .custom_nav ul > li:hover > .dropdown-menu {
        opacity: 1;
        visibility: visible;
        top: 145%;
    }

    .navbar-right .dropdown-menu {
        right: 0;
        left: 2px;
        width: 240px;
    }
}

/* === nav-toggle === */

ul.tog-nav li a {
    opacity: 0 !important;
}

.full_nav .nav.tog-nav > li {
    visibility: hidden;
}

.full_nav .nav > li:last-child {
    visibility: visible;
}

.full_nav .nav > li {
    visibility: visible;
}

.navbar-brand > img {
    display: block;
}

.navbar-toggle {
    display: none;
}

/* == menu css start == */


nav.navbar .container {
    position: relative;
}

.navbar-header {
    float: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    transition: .4s;
}

.menuVisible.navbar-header {
    left: 0;
    transform: initial;
}

.full_nav .nav {
    margin-top: 10px;
    border-radius: 30px;
    width: 650px;
    margin-left: 10px !important;
}

.full_nav .nav > li {
    margin: 0 4px;
    padding: 10px 0;
}

.full_nav .nav > li > a {
    font-size: 18px;
    position: relative;
    line-height: 24px;
    font-weight: 600;
    font-family: "Nunito";
    padding: 10px 15px;
    color: #fff;
    transition: all 0.3s linear;
    position: relative;
    line-height: normal;
    text-transform: capitalize;
}

.full_nav .nav > li a.active,
.full_nav .nav > li:hover a {
    color: #e8a004;
}

.menu-opener,
.menu-opener:hover,
.menu-opener.active,
.menu-opener-inner,
.menu-opener-inner::before,
.menu-opener-inner::after,
.menu,
.menu.active {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.menu-opener-inner.active .fa-align-right {
    opacity: 0;
}

.fa-time {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.full_nav .nav > li:last-child .fa-times {
    position: absolute;
    top: 11px;
    z-index: -1;
    font-size: 22px;
}

.full_nav .nav > li:last-child {
    cursor: pointer;
    position: absolute;
    right: 285px;
    top: 14px;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    border: 1px solid #e8a004;
    color: #e8a004;
    background: transparent;
    padding: 10px 14px;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.menu-inner.tog-nav .fa-times {
    opacity: 0;
}

.menu-inner.tog-nav i.fa-align-right {
    opacity: 1;
}

.menu-inner i.fa-align-right {
    opacity: 0;
}
/* ============================= 
       Banner Css 
======================================= */

.main_page {
    background: url(../images/banner-background.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    position: relative;
}

@media (max-width: 760px) {
    .main_page{
            height: 60vh;
    }
}

.banner-center {
    position: absolute;
    top: 60%;
    left: 0%;
    width: 600px;
    text-align: center;
    transform: translateY(-50%);
}

.title {
    font-size: 100px;
    letter-spacing: -1px;
    line-height: 110px;
    width: 570px;
    font-weight: 400;
    font-family: "Anton";
    background: -webkit-linear-gradient(#fcb503 46%, #e5881b 54%, #fcb11a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index: 99;
}

.title:before {
    position: absolute;
    content: 'Online Casino';
    text-shadow: 21px 13px 2px #2c0b49;
    filter: drop-shadow(0 0 40px #68248c);
    left: 0;
    z-index: -99;
}

.banner_text h3 {
    font-size: 46px;
    line-height: 46px;
    font-weight: 300;
    font-family: "Nunito";
    color: #fff;
    text-transform: capitalize;
    padding-bottom: 60px;
}

.banner_text p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    font-family: "Nunito";
    color: #fff;
    text-transform: capitalize;
    padding-bottom: 30px;
}

/*=====================================
 Counter
======================================*/
.conter-res .col-md-1 {
    max-width: 10.333333%;
}

.conter-res {
    position: relative;
    border-radius: 2px;
    margin-top: -70px;
    padding: 40px 10px;
    -webkit-box-shadow: 0px 5px 35px 0px rgba(105, 90, 166, 0.23);
    -moz-box-shadow: 0px 5px 35px 0px rgba(105, 90, 166, 0.23);
    box-shadow: 0px 5px 35px 0px rgba(105, 90, 166, 0.23);
}

.border-count {
    width: 100px;
}

.counter-left h4 {
    font-size: 42px;
    text-shadow: 3px 2px 0px rgba(0, 0, 0, 0.3);
    color: #fff;
    line-height: 24px;
    font-weight: 400;
    font-family: "Anton";
    text-transform: uppercase;
    cursor: auto;
    letter-spacing: 0px;
    padding-top: 20px;
}

.counterinner ul li {
    float: left;
    width: 65px;
    border: 1px solid #300272;
    border-image-source: linear-gradient(#e26aca, #943285);
    border-image-slice: 4;
    margin: 0 5px;
    padding: 19px;
}

.counter-text h3 {
    font-size: 50px;
    color: #943184;
    line-height: 24px;
    font-weight: 400;
    font-family: "Anton", sans-serif;
    text-transform: uppercase;
    cursor: auto;
}


/* =====================================
 Counter end 
======================================= */

/* ===========================================================
    BE IN CONTROL
=============================================================== */
.control {
    padding-bottom: 80px;
    overflow: hidden;
}

.control-img {
    height: 100%;
    background: -webkit-linear-gradient(to bottom, rgba(254, 215, 125, 1) 0%, rgba(247, 172, 4, 1) 100%) !important;
    background: -o-linear-gradient(to bottom, rgba(254, 215, 125, 1) 0%, rgba(247, 172, 4, 1) 100%);
    background: -moz-linear-gradient(to bottom, rgba(254, 215, 125, 1) 0%, rgba(247, 172, 4, 1) 100%);
    background: linear-gradient(to bottom, rgba(254, 215, 125, 1) 0%, rgba(247, 172, 4, 1) 100%);
    filter: drop-shadow(0 0px 20px rgba(101, 40, 193, 0.5));
    box-shadow: 0px 0px 4px 1px rgba(247, 172, 4, 0.8);
    border: 1px solid rgba(224, 180, 6, 0.7);
    border-radius: 2px;
    padding: 23px 24px;
}

.control-img:hover {
    background: -webkit-linear-gradient(to bottom, rgba(223, 103, 198, 1) 0%, rgba(152, 51, 134, 1) 100%) !important;
    background: -o-linear-gradient(to bottom, rgba(223, 103, 198, 1) 0%, rgba(152, 51, 134, 1) 100%);
    background: -moz-linear-gradient(to bottom, rgba(223, 103, 198, 1) 0%, rgba(152, 51, 134, 1) 100%);
    background: linear-gradient(to bottom, rgba(223, 103, 198, 1) 0%, rgba(152, 51, 134, 1) 100%);
    box-shadow: 0px 0px 4px 1px rgba(152, 51, 134, 0.9);
    border: 1px solid rgba(226, 71, 237, 0.5);
    filter: drop-shadow(0 0px 20px rgba(152, 51, 134, 0.6));
    border-radius: 2px;
    transition: all ease 0.5s;
    -webkit-transition: all ease 0.5s;
    -moz-transition: all ease 0.5s;
    -ms-transition: all ease 0.5s;
    -o-transition: all ease 0.5s;

}

.control-img img {
    padding: 20px;
}

.control-text {
    text-align: left;
    color: #fff;
}

.control-text h3 {
    font-size: 20px;
    position: relative;
    line-height: 24px;
    font-weight: 700;
    font-family: "Nunito", sans-serif;
    text-transform: uppercase;
    margin-bottom: 15px;
    cursor: auto;
}

.control-text p {
    font-size: 16px;
    position: relative;
    line-height: 24px;
    font-weight: 400;
    font-family: "Nunito", sans-serif;
    text-transform: capitalize;
    cursor: auto;
}

.control-pad {
    position: relative;
    padding: 30px 0;
    margin-bottom: 60px;
}

.control-pad:after {
    position: absolute;
    content: '';
    border-bottom: 2px solid #290a59;
    width: 3600px;
    left: 50%;
    top: 58px;
    transform: translateX(-50%);
}

.control-pad:before {
    position: absolute;
    content: '';
    border-bottom: 2px solid #000;
    width: 3600px;
    left: 50%;
    transform: translateX(-50%);
    top: 60px;
}

.control [class^="flaticon-"]:before,
.control [class*=" flaticon-"]:before,
.control [class^="flaticon-"]:after,
.control [class*=" flaticon-"]:after {

    margin-left: 0px !important;
    font-size: 70px !important;
}

.control-img i {
    background: -webkit-linear-gradient(#e26aca 0%, #943285 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.control-img:hover i {
    background: -webkit-linear-gradient(#ffd370 0%, #f7ad07 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all ease 0.4s;
    -webkit-transition: all ease 0.4s;
    -moz-transition: all ease 0.4s;
    -ms-transition: all ease 0.4s;
    -o-transition: all ease 0.4s;
}

/* ===========================================================
    HOW TO START
=============================================================== */
.how-start {
    background: #0b001b;
    padding-bottom: 80px;
}

.start-img {
    height: 70px;
    width: 70px;
    background: -webkit-linear-gradient(to bottom, rgba(254, 215, 125, 1) 0%, rgba(247, 172, 4, 1) 100%) !important;
    background: -o-linear-gradient(to bottom, rgba(254, 215, 125, 1) 0%, rgba(247, 172, 4, 1) 100%);
    background: -moz-linear-gradient(to bottom, rgba(254, 215, 125, 1) 0%, rgba(247, 172, 4, 1) 100%);
    background: linear-gradient(to bottom, rgba(254, 215, 125, 1) 0%, rgba(247, 172, 4, 1) 100%);
    padding: 14px 17px;
    filter: drop-shadow(0 0px 20px rgba(101, 40, 193, 0.5));
    box-shadow: 0px 0px 4px 1px rgba(247, 172, 4, 0.8);
    border: 1px solid rgba(224, 180, 6, 0.7);
}

.start-img:hover {
    background: -webkit-linear-gradient(to bottom, rgba(223, 103, 198, 1) 0%, rgba(152, 51, 134, 1) 100%) !important;
    background: -o-linear-gradient(to bottom, rgba(223, 103, 198, 1) 0%, rgba(152, 51, 134, 1) 100%);
    background: -moz-linear-gradient(to bottom, rgba(223, 103, 198, 1) 0%, rgba(152, 51, 134, 1) 100%);
    background: linear-gradient(to bottom, rgba(223, 103, 198, 1) 0%, rgba(152, 51, 134, 1) 100%);
    box-shadow: 0px 0px 4px 1px rgba(152, 51, 134, 0.9);
    border: 1px solid rgba(226, 71, 237, 0.5);
    transition: all ease 0.5s;
    -webkit-transition: all ease 0.5s;
    -moz-transition: all ease 0.5s;
    -ms-transition: all ease 0.5s;
    -o-transition: all ease 0.5s;
    filter: drop-shadow(0 0px 20px rgba(152, 51, 134, 0.6));
}

.start-img [class^="flaticon-"]:before,
.start-img [class*=" flaticon-"]:before,
.start-img [class^="flaticon-"]:after,
.start-img [class*=" flaticon-"]:after {

    margin-left: 0px !important;
    font-size: 40px !important;
}

.start-img i {
    background: -webkit-linear-gradient(#e26aca 0%, #943285 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.start-img:hover i {
    background: -webkit-linear-gradient(#ffd370 0%, #f7ad07 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all ease 0.4s;
    -webkit-transition: all ease 0.4s;
    -moz-transition: all ease 0.4s;
    -ms-transition: all ease 0.4s;
    -o-transition: all ease 0.4s;
}

.start-text {
    text-align: left;
    color: #fff;
    padding-left: 5px;
    padding-right: 15px;
}

.start-text h3 {
    font-size: 20px;
    position: relative;
    line-height: 24px;
    font-weight: 700;
    font-family: "Nunito", sans-serif;
    text-transform: uppercase;
    margin-bottom: 15px;
    cursor: auto;
}

.start-text p {
    font-size: 16px;
    position: relative;
    line-height: 24px;
    font-weight: 400;
    font-family: "Nunito";
    text-transform: capitalize;
    cursor: auto;
}

/*=====================================
 End of Counter  part css
=======================================*/

/*=====================================
    Related other games Start
=======================================*/

.overlay-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 24px;
    color: #fff;
    text-transform: uppercase;
    font-size: 1.25em;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.overlay-text:before {
    position: absolute;
    top: 0%;
    left: -10%;
    width: 120%;
    height: 120%;
    content: '';
    -webkit-transition: -webkit-transform 0.6s;
    transition: -webkit-transform 0.6s;
    -o-transition: transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
    -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -120%, 0);
    transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -120%, 0);
    background-color: rgba(0, 0, 0, 0.5);
}

.port_img:hover .overlay-text:before {
    -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 130%, 0);
    transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 130%, 0);
}

.project-img {
    padding-bottom: 80px;
}
.in-project {
    padding-bottom: 38px;
    margin-bottom: -50px;
}

.project-img .nav-menu {
/*    margin: 0 3px;*/
    -webkit-transition: ease all .5s;
    -moz-transition: ease all .5s;
    -o-transition: ease all .5s;
    transition: ease all .5s;
    display: inline-block;
    padding: 10px 20px;
    background: transparent;
    border: none;
    color: #fff;
    font-family: "Nunito", sans-serif;
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;
    text-transform: capitalize;
    outline: 0;
}

.nav-menu:hover {
    color: #e8a004;
    outline: 0;
}

.nav-menu:active,
.nav-menu.is-checked {
    background-color: transparent;
    outline: 0;
}

.nav-menu.is-checked {
    color: #e8a004;
    text-shadow: 0 -1px hsla(0, 0%, 0%, 0.8);
}
.button-group {
    display: contents;
}
.project-img .nav-pills{
    margin-bottom: 40px;
}
.project-img .heading {
    margin-bottom: 20px;
}

.overlay_shape1 i {
    position: absolute;
    font-size: 24px;
    color: #fff;
    font-weight: 300;
    left: 50%;
    border-radius: 100%;
    background: transparent;
    transform: translateX(-50%);
}

.port-text {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
}

.port-text-btm {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
}

.port-text-btm h3 {
    text-align: center;
    font-size: 24px;
    padding-bottom: 2px;
    color: #fff;
    line-height: 24px;
    font-weight: 700;
    font-family: "Nunito";
    text-transform: capitalize;
}

.port-text-btm p {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    font-weight: 400;
    font-family: "Nunito";
    text-transform: capitalize;
}

.overlay1 {
    position: absolute;
    text-align: center;
    top: 0%;
    left: 0%;
    right: 0%;
    background: rgba(0, 0, 0, 0.7);
    overflow: hidden;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all .4s ease-in;
}

.port_img {
    position: relative;
    overflow: hidden;
    transition: all .4s ease-in;
    margin-bottom: 30px;
}

.port_img img {
    width: 100%;
    transition: all .4s ease-in;
}

.port_img:hover .overlay1 {
    opacity: 1;

}

.port_img:hover img {
    -webkit-transform: scale(1.06);
    transform: scale(1.06);
}

.start-btn {
    margin-top: 30px;
}

.games-page {
    padding-bottom: 60px;
}

/*=====================================
 End Related other games Css
=======================================*/

/*=====================================
    Start of Unlock Free spin part css
=======================================*/
.free-spin {
    padding-bottom: 40px;
}

.spin-text {
    padding-top: 30px;
}

.spin-text .casino-btn {
    padding-top: 40px;
}

.spin-text p {
    color: #fff;
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

/*=====================================
    End of Unlock Free spin  part css
=======================================*/
/*========================================
            Casino Jackpots Start 
========================================*/
.jackpots {
    padding-bottom: 60px;
}

.tour-jack {
    padding-bottom: 40px;
}

.touna_left_inner {
    margin-top: 45px;
}

.touna_left_inner .card {
    background: #150035;
    border: none;
    background-clip: inherit;

}

.touna_left_inner .card.add_border {
    border: 1px solid #fcb100;
    border-radius: 0;
}

.touna_left_inner button {
    text-decoration: none !important;
    padding-left: 30px !important;
    width: 100%;
    text-align: left;
    margin-top: 25px;
}

.touna_left_inner button i {
    float: right;
    color: #fffeff;
    padding-right: 50px;
    padding-top: 9px;
}

.touna_left_inner button span {
    color: #fffeff;
    font-size: 20px;
    font-weight: 400;
    font-family: 'Nunito', sans-serif;
}

.touna_head_left .card-header {
    padding: 0;
}

.touna_head_left .card-body {
    padding: 0;
    padding-left: 30px;
}

.touna_head_left .btn {
    padding: 0;
}

.card-body p {
    color: #fffeff;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Nunito', sans-serif;
    padding-bottom: 25px;
}

.btn:not(:disabled):not(.disabled) {
    padding-bottom: 25px;
}

.accordion .add_border:not(:first-of-type):not(:last-of-type) {
    border-bottom: 1px solid #fcb100 !important;

}

.toun_scroll_flag {
    padding-left: 25px;
}

.scl_pad {
    padding-top: 15px;
    padding-bottom: 10px;
    position: relative;
}

.scl_pad img {
    padding-top: 20px;
}

.toun_scroll_flag img {
    padding-top: 0px;
}

.jack-border {
    position: relative;
}

.jack-border:after {
    position: absolute;
    content: '';
    border-bottom: 2px solid #290a59;
    width: 350px;
    left: 50%;
    transform: translateX(-50%);
    top: -23px;
}

.jack-border:before {
    position: absolute;
    content: '';
    border-bottom: 2px solid #000;
    width: 350px;
    left: 50%;
    transform: translateX(-50%);
    top: -24px;
}

.jack-border .border-effect2 {
    right: -148px;
    top: -23px;
    width: 211px;
}

.jack-border .border-effect1 {
    top: -23px;
    width: 223px;
    left: 1px;
}

.winner-name h5 {
    width: 150px;
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Nunito', sans-serif;
}

.counter-jack {
    padding-bottom: 30px;
   
}

.running-img .img-fluid {
    margin-bottom: 30px;
}

.running-img {
    position: relative;
    overflow: hidden;
}

.running-img:before {
    content: "";
    position: absolute;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9)30%, rgba(0, 0, 0, 0)70%);
    top: -30px;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.run-txt {
    position: absolute;
    left: 50%;
    top: 70%;
    transform: translate(-50%, -50%);
    overflow: hidden;
}

.run-txt h4 {
    color: #fff;
    font-size: 24px;
    position: relative;
    line-height: 24px;
    font-weight: 400;
    font-family: 'Anton', sans-serif;
    text-transform: uppercase;
    z-index: 0;
    padding-top: 5px;
    padding-bottom: 20px;
}

.run-txt p {
    color: #fff;
    font-size: 24px;
    position: relative;
    line-height: 24px;
    font-weight: 400;
    font-family: 'Nunito', sans-serif;
    text-transform: uppercase;
    z-index: 0;
    padding-bottom: 35px;
}

.running-img .casino-btn {
    opacity: 0;
}

.running-img:hover::before {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9)30%, rgba(0, 0, 0, 0.9)70%);
    -webkit-transition: ease all 1s;
    -moz-transition: ease all 1s;
    -o-transition: ease all 1s;
    transition: ease all 1s;
}

.running-img:hover .run-txt {
    top: 43%;
    transition: all ease 0.4s;
    -webkit-transition: all ease 0.4s;
    -moz-transition: all ease 0.4s;
    -ms-transition: all ease 0.4s;
    -o-transition: all ease 0.4s;
}

.running-img:hover .run-txt h4 {
    background: -webkit-linear-gradient(#ffd370 0%, #f7ad07 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-transition: ease all 1s;
    -moz-transition: ease all 1s;
    -o-transition: ease all 1s;
    transition: ease all 1s;
}

.running-img:hover .casino-btn {
    opacity: 1;
    transition: all 0.9s linear;
    -o-transition: all 0.9s linear;
    -ms-transition: all 0.9s linear;
    -moz-transition: all 0.9s linear;
    -webkit-transition: all 0.9s linear;
}

.coun_text {
    margin-top: 85px;
}

.coun_text h4 {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Nunito', sans-serif;
}

.coundown {
    float: left;
    margin-bottom: 65px;
    border-radius: 5px;
    border: 1px solid #ffd370;
    /*border-image-source: linear-gradient(#ffd370, #f7ad07);*/
    border-image-slice: 2;
    height: 150px;
    width: 150px;
    margin-left: 22px;
    text-align: center;
    padding-top: 45px;
}

.coundown span {
    background: -webkit-linear-gradient(#ffd370 0%, #f7ad07 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 78px;
    font-weight: 600;
    line-height: 24px;
    font-family: 'Nunito', sans-serif;
}

.coundown p {
    color: #fcb100;
    font-size: 32px;
    font-weight: 600;
    font-family: 'Nunito', sans-serif;
}

.count-dot {
    position: relative;
}

.count-dot:before {
    content: "";
    position: absolute;
    border-left: 2px dotted #ffd370;
    top: -65px;
    left: 47%;
    height: 65px;
    width: 100%;
}

.count-dot:after {
    content: "";
    position: absolute;
    border-left: 2px dotted #ffd370;
    top: 150px;
    left: 47%;
    height: 64px;
    width: 100%;
}

/*=====================================
    Start of Faq  part css
=======================================*/
.faq {
    padding-bottom: 80px;
}

.faq-border {
    position: absolute;
    top: 0px;
    left: 52%;
    transform: translateX(-50%);
}

.faq-pad {
    position: relative;
    margin-bottom: 30px;
    margin-top: 30px;
}

.faq-inner h3 {
    color: #fff;
    font-family: "Anton", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    padding-bottom: 20px;
}

.faq-inner p {
    color: #fff;
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    width: 370px;
}

.f-padding {
    padding-left: 40px;
}
.faq .form-group textarea,
.faq input {
    width: 100%;
    display: inline-block;
    border: 1px solid #fff;
    font-size: 14px;
    color: #ddd !important;
    border-radius: 0;
    background: transparent;
    padding-left: 20px;
    font-family: "Nunito", sans-serif;
}

.faq .form-group textarea::placeholder,
.faq .form-group input::placeholder {
    color: #fff;
}

.faq .form-control:focus {
    color: #fff;
    background-color: transparent;
    border-color: #fff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}

.faq .form-group textarea {
    height: 150px;
    padding-top: 10px;
    color: #5c2ea0;
    resize: none;
}

.faq input {
    height: 50px;
}

.faq input::placeholder {
    color: #bfbfbf;
    opacity: 1;
    /* Firefox */
}

.faq-btn {
    border-radius: 0 !important;
    width: 100%;
}

/*=====================================
    End of Faq  part css
=======================================*/

/*=====================================
    Start of contact  part css
=======================================*/
.payments ul li {
    float: left;
    padding-right: 11px;
}

.payments ul li img {
    border-radius: 5px;
}

.contact .heading {
    margin-bottom: 30px;
}

.heading h3 {
    font-size: 32px;
    position: relative;
    line-height: 24px;
    font-weight: 400;
    font-family: 'Anton', sans-serif;
    background: -webkit-linear-gradient(#ffd370 55%, #f7ad07 68%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    z-index: 0;
    padding-top: 5px;
    padding-bottom: 30px;
    cursor: auto;
    letter-spacing: -1px;
    padding-top: 65px;
}
/*

.contact-about .border-effect1 {
    position: absolute;
    top: 45px;
    z-index: 99;
    width: 255px;
    left: 33px;
    animation: scan 8s infinite;
}
*/

/*
.contact-about .border-effect2 {
    position: absolute;
    top: 45px;
    z-index: 99;
    width: 250px;
    left: 370px;
    animation: scan2 8s infinite;
}
*/

.sub-form .col-sm-12 {
    padding-left: 0px;
    padding-right: 3px;
}

.sub-form,
.subscribe {
    position: relative;
}

.subscribe .border-effect1 {
    position: absolute;
    top: 105px;
    z-index: 99;
    width: 190px;
    left: 10px;
    animation: scan 8s infinite;
}

.subscribe .border-effect2 {
    position: absolute;
    top: 105px;
    z-index: 99;
    width: 190px;
    left: 270px;
    animation: scan2 8s infinite;
}

.contact-us .heading h2 {
    font-size: 32px;
}

.contact-app {
    text-align: center;
}

.contact-app ul {
    display: inline-block;
    text-align: center;
    padding-bottom: 30px;
}

.contact-app li {
    float: left;
    font-size: 20px;
    color: #f7ab00;
    line-height: 24px;
    font-family: "Nunito", sans-serif;
}

.contact-app li a {
    color: #fff;
}

.contact-app li a i {
    width: 90px;
    height: 90px;
    line-height: 90px;
    border-radius: 100px;
    background: linear-gradient(to bottom, #ffd679 30%, #f7ac03 70%);
    filter: drop-shadow(0 0px 20px rgba(101, 40, 193, 0.5));
    box-shadow: 0px 0px 2px 1px rgba(247, 172, 4, 0.8);
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    margin-bottom: 10px;
}

.contact-app [class^="flaticon-"]:before,
.contact-app [class*=" flaticon-"]:before,
.contact-app [class^="flaticon-"]:after,
.contact-app [class*=" flaticon-"]:after {

    margin-left: 0px !important;
    font-size: 40px !important;
}

.contact-app li:first-child {
    margin-right: 50px;
}

.contact-app li:hover {
    color: #943184;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.contact-app li:hover i {
    background: linear-gradient(to bottom, #e673cf 10%, #9a368a 90%);
    box-shadow: 0px 0px 3px 1px rgba(152, 51, 134, 0.4);
    filter: drop-shadow(0 0px 20px rgba(152, 51, 134, 0.6));
    -webkit-transition: all 0.3s ease-in-out 0.4s;
    -moz-transition: all 0.3s ease-in-out 0.4s;
    -ms-transition: all 0.3s ease-in-out 0.4s;
    -o-transition: all 0.3s ease-in-out 0.4s;
    transition: all 0.3s ease-in-out 0.4s;
}

.contact-about .heading:before {
    width: 440px;
}

.contact-about .heading:after {
    width: 440px;
}

.app-icon .heading:before {
    width: 260px;
}

.app-icon .heading:after {
    width: 260px;
}

.app-icon .border-effect1 {
    position: absolute;
    top: 45px;
    z-index: 99;
    width: 137px;
    left: 18px;
    animation: scan 8s infinite;
}

.app-icon .border-effect2 {
    position: absolute;
    top: 45px;
    z-index: 99;
    width: 137px;
    left: 200px;
    animation: scan2 8s infinite;
}

.payments .heading:before {
    width: 335px;
}

.payments .heading:after {
    width: 340px;
    left: 48%;
}

.payments .border-effect1 {
    position: absolute;
    top: 45px;
    z-index: 99;
    width: 195px;
    left: 33px;
    animation: scan 8s infinite;
}

.payments .border-effect2 {
    position: absolute;
    top: 45px;
    z-index: 99;
    width: 190px;
    left: 270px;
    animation: scan2 8s infinite;
}

.subscribe .heading:before {
    width: 340px;
    top: 120px;
}

.subscribe .heading:after {
    width: 340px;
    left: 49%;
}

.subscribe input {
    width: 100%;
    display: inline-block;
    border: 1px solid #fff;
    font-size: 14px;
    color: #fff !important;
    border-radius: 0;
    background: transparent;
    padding-left: 20px;
    font-family: "Nunito", sans-serif;
}

.subscribe .form-group input::placeholder {
    color: #fff;
}

.subscribe .form-control:focus {
    color: #fff;
    background-color: transparent;
    border-color: #fff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}

.subscribe input {
    height: 50px;
}

.newsletter a {
    border-radius: 0;
    position: absolute;
    top: 0;
    right: 0;
}

.contact .control-pad {

    margin-bottom: 30px;
}

.foot-menu ul {
    display: inline-block;
    text-align: center;
    padding-bottom: 30px;
}

.foot-menu ul li {
    float: left;
}

.foot-menu ul li a {
    font-size: 20px;
    line-height: 24px;
    padding: 0 15px;
    font-family: "Nunito", sans-serif;
    color: #fff;
    text-transform: capitalize;
}

.foot-menu ul li a:hover {
    color: #f7ab00;
    transition: all ease 0.5s;
    -webkit-transition: all ease 0.5s;
    -moz-transition: all ease 0.5s;
    -ms-transition: all ease 0.5s;
    -o-transition: all ease 0.5s;
}

.copy-right h6 {
    font-size: 16px;
    line-height: 24px;
    font-family: "Nunito", sans-serif;
    color: #5c2ea0;
    padding-bottom: 20px;
}

/*=====================================
    End of contact  part css
=======================================*/
/* ====================================
          About Page css
========================================*/
.about-page {
    padding-bottom: 80px;
    padding-top: 80px;
}

.about-page .heading {
    margin-bottom: 35px;
}

.about-video {
    position: relative;
}

.about-video:before {
    content: '';
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    top: 0px;
}

.about-video img {
    z-index: 9;
}

.youtube_video {
    position: absolute;
    top: 41%;
    left: 46%;
    transform: translate(-50%, -50%);
    z-index: 99;
}

.youtube_video a i {
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    width: 80px;
    height: 80px;
    background: linear-gradient(to bottom, rgba(254, 215, 125, 1) 0%, rgba(247, 172, 4, 1) 100%);
    border-radius: 50%;
    line-height: 80px;
    animation: bulb 4s infinite;
    font-size: 25px;
    text-shadow: 5px 3px 0px rgba(0, 0, 0, .1);
    padding: 0 34px;
    -webkit-transition: all ease 0.5s;
    -moz-transition: all ease 0.5s;
    -ms-transition: all ease 0.5s;
    -o-transition: all ease 0.5s;
    transition: all ease 0.5s;
}

.youtube_video a i:hover {
    background: linear-gradient(to bottom, #e673cf 10%, #9a368a 90%);
}

.about-page .heading:after,
.about-page .heading:before {
    left: 16px;
    width: 520px;
    transform: translateX(0%);
}

.about-page .casino-btn {
    margin-top: 30px;
}

/* ===============================
           error page
==================================*/
.error {
    /* background: url(../images/404-image.jpg); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 85vh;
    position: relative;
    overflow: hidden;
}

.error-center {
    position: absolute;
    top: 43%;
    left: 50%;
    width: 600px;
    text-align: center;
    transform: translate(-50%, -50%);
}

.error-txt {
    position: relative;
}

.error-txt h4 {
    font-size: 130px;
    font-family: "Anton", sans-serif;
    line-height: 120px;
    background: -webkit-linear-gradient(#fcb503 43%, #e5881b 57%, #fcb11a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    animation: bulb 10s infinite;
}

.error-txt:before {
    content: 'Not Found';
    font-size: 34px;
    font-family: "Anton", sans-serif;
    line-height: 34px;
    color: #fff;
    text-transform: uppercase;
    position: absolute;
    top: 105%;
    left: 78%;
    width: 600px;
    text-align: center;
    transform: translate(-50%, -50%) rotate(-20deg);
}

.error-btn {
    position: absolute;
    top: 95%;
    left: 50%;
    width: 600px;
    text-align: center;
    transform: translate(-50%, -50%);
}

.error .casino-btn a {
    filter: drop-shadow(0 0px 20px rgba(101, 40, 193, 0));
}

/* ==========================================
          contact us page 
===============================================*/
.contact-page {
    padding-bottom: 60px;
    background: url(../images/contact-bg-image.jpg) no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    z-index: 0;
}

.contact-page:before {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.9);
    z-index: 0;
}

.contact-page .form-group textarea,
.contact-page input {
    width: 100%;
    display: inline-block;
    border: 1px solid #fff;
    font-size: 14px;
    color: #ddd !important;
    border-radius: 0;
    background: transparent;
    padding-left: 20px;
    font-family: "Nunito", sans-serif;
}

.contact-page .form-group textarea::placeholder,
.contact-page .form-group input::placeholder {
    color: #fff;
}

.contact-page .form-control:focus {
    color: #fff;
    background-color: transparent;
    border-color: #fff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}

.contact-page .form-group textarea {
    height: 150px;
    padding-top: 10px;
    color: #fff;
    resize: none;
}

.contact-page input {
    height: 50px;
}

.contact-page input::placeholder {
    color: #fff;
    opacity: 1;
}

.contact-page .casino-btn a {
    filter: drop-shadow(0 0px 20px rgba(255, 214, 121, 0.3));
}

.contact-page .casino-btn a:hover {
    filter: drop-shadow(0 0px 20px rgba(152, 51, 134, 0.6));
}
.contact-page .heading:after {
    width: 535px;
    left: 48%;
}
.contact-page .border-effect1 {
    width: 200px;
    left: 80px;
    animation: scan 8s infinite;
}
.contact-page .border-effect2 {
    width: 200px;
    right: -70px;
}
.location ul li {
    font-size: 18px;
    line-height: 24px;
    font-family: "Nunito", sans-serif;
    color: #fff;
    font-weight: 600;
    display: flex;
}

.location-icon i {
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    border-radius: 100px;
    font-size: 24px;
    background: linear-gradient(to bottom, #ffd679 30%, #f7ac03 70%);
    filter: drop-shadow(0 0px 20px rgba(255, 214, 121, 0.3));
    box-shadow: 0px 0px 2px 1px rgba(247, 172, 4, 0.8);
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    margin-bottom: 60px;
    color: #fff;

}

.location-txt {
    padding-right: 130px;
}

.location-txt span {
    line-height: 26px;
    font-size: 16px;
    font-family: "Nunito", sans-serif;
    color: #fff;
}


.location li i {
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    border-radius: 100px;
    font-size: 24px;
    background: linear-gradient(to bottom, #ffd679 30%, #f7ac03 70%);
    filter: drop-shadow(0 0px 20px rgba(101, 40, 193, 0.5));
    box-shadow: 0px 0px 2px 1px rgba(247, 172, 4, 0.8);
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    margin-bottom: 60px;
}

/* */
.award-imgfixed {
    position: sticky !important;
    top: 100px;
}

/* ===============================
            animation
==================================*/
@keyframes bulb {
    0% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
        opacity: 1;
    }

    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0.9;
    }

    100% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
        opacity: 1;
    }
}

@keyframes movebounce {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(20px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes moveleftbounce {
    0% {
        transform: translateX(-10px);
    }

    50% {
        transform: translateX(30px);
    }

    100% {
        transform: translateX(-10px);
    }
}

@keyframes moverightbounce {
    0% {
        transform: translateX(10px);
    }

    50% {
        transform: translateX(-30px);
    }

    100% {
        transform: translateX(10px);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(50deg);
    }

    100% {
        transform: rotate(0deg);
    }
}


/* ======== Sub Header css ======= */




/* ======== hidden==========*/
.hidden {
    display: none;
}

/* === BACK TO TOP CSS == */

#back-top-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: -webkit-linear-gradient(to bottom, rgba(254, 215, 125, 1) 0%, rgba(247, 172, 4, 1) 100%) !important;
    background: -o-linear-gradient(to bottom, rgba(254, 215, 125, 1) 0%, rgba(247, 172, 4, 1) 100%);
    background: -moz-linear-gradient(to bottom, rgba(254, 215, 125, 1) 0%, rgba(247, 172, 4, 1) 100%);
    background: linear-gradient(to bottom, rgba(254, 215, 125, 1) 0%, rgba(247, 172, 4, 1) 100%);
    width: 50px;
    height: 50px;
    display: block;
    text-decoration: none;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, .2);
    display: none;
    -webkit-transition: ease all .5s;
    -moz-transition: ease all .5s;
    -o-transition: ease all .5s;
    transition: ease all .5s;
    z-index: 999;
    padding: 12px;
    text-align: center;
    filter: drop-shadow(0 0px 10px rgba(254, 215, 125, 0.2));
}

#back-top-btn i {
    position: relative;
    color: #fff;
    -webkit-transition: ease all .5s;
    -moz-transition: ease all .5s;
    -o-transition: ease all .5s;
    transition: ease all .5s;
}

#back-top-btn:hover {
    background: -webkit-linear-gradient(to bottom, rgba(223, 103, 198, 1) 0%, rgba(152, 51, 134, 1) 100%) !important;
    background: -o-linear-gradient(to bottom, rgba(223, 103, 198, 1) 0%, rgba(152, 51, 134, 1) 100%);
    background: -moz-linear-gradient(to bottom, rgba(223, 103, 198, 1) 0%, rgba(152, 51, 134, 1) 100%);
    background: linear-gradient(to bottom, rgba(223, 103, 198, 1) 0%, rgba(152, 51, 134, 1) 100%);
    box-shadow: 0px 0px 40px 4px rgba(0, 0, 0, .3);
    -webkit-box-shadow: 0px 0px 40px 4px rgba(0, 0, 0, .3);
    -moz-box-shadow: 0px 0px 40px 4px rgba(0, 0, 0, .3);
    -o-box-shadow: 0px 0px 40px 4px rgba(0, 0, 0, .3);
    filter: drop-shadow(0 0px 10px rgba(223, 103, 198, 0.3));
}

/* =================================================
                 Animation
==================================================*/
@-webkit-keyframes scan {

    from,
    0%,
    100% {

        -webkit-transform: translate(0%, 0);
        transform: translate(0%, 0);
    }

    50% {

        -webkit-transform: translate(130%, 0);
        transform: translate(130%, 0);
    }
}

@keyframes scan {

    from,
    0%,
    100% {

        -webkit-transform: translate(0%, 0);
        transform: translate(0%, 0);
    }

    50% {

        -webkit-transform: translate(130%, 0);
        transform: translate(130%, 0);
    }
}

@-webkit-keyframes scan2 {

    from,
    0%,
    100% {

        -webkit-transform: translate(0%, 0);
        transform: translate(0%, 0);
    }

    50% {

        -webkit-transform: translate(-130%, 0);
        transform: translate(-130%, 0);
    }
}

@keyframes scan2 {

    from,
    0%,
    100% {

        -webkit-transform: translate(0%, 0);
        transform: translate(0%, 0);
    }

    50% {

        -webkit-transform: translate(-130%, 0);
        transform: translate(-130%, 0);
    }
}




.fa-icon {
    font-size: 50px;
}
.fa-icon-1{
    font-size: 30PX;

}

.contact-app {
  text-align: center;
  background-color: #0e0a1d; /* Background similar to your image */
  padding: 20px;
}

.contact-app ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
}

.contact-app li {
  margin: 0 20px;
  text-align: center;
}

.icon-wrapper {
  background-color: #ffaa00; /* Orange circle */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.icon-wrapper svg {
  color: #fff; /* White icon color */
}

.text {
  color: #ffaa00; /* Matching the text color with the icon's background */
  font-size: 16px;
  font-weight: bold;
  display: block;
  margin-top: 10px;
}
.contact-app {
    text-align: center;
    background-color: #0e0a1d;
    /* Background similar to your image */
    padding: 20px;
}

.contact-app ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
}

.contact-app li {
    margin: 0 20px;
    text-align: center;
}

.icon-wrapper {
    background-color: #ffaa00;
    /* Orange circle */
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    transition: background-color 0.3s ease;
    /* Smooth transition */
}

.icon-wrapper svg {
    color: #fff;
    /* White icon color */
    transition: color 0.3s ease;
    /* Smooth transition */
}

.icon-wrapper:hover {
    background-color: #6a0dad;
    /* Purple color on hover */
}

.icon-wrapper:hover svg {
    color: #fff;
    /* Keep the icon white */
}

.text {
    color: #ffaa00;
    /* Matching the text color with the icon's background */
    font-size: 16px;
    font-weight: bold;
    display: block;
    margin-top: 10px;
    transition: color 0.3s ease;
    /* Smooth transition */
}

.contact-app li:hover .text {
    color: #6a0dad;
    /* Change text color to purple on hover */
}


.refund-policy-container {
  padding: 20px;
  /* margin-top: 100px; */
  margin: 20px auto;
  max-width: 800px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* background-color: #fff; */
}

.refund-policy-container h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.refund-policy-container p {
  font-size: 16px;
  line-height: 1.6;
}

.refund-policy-container h3 {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.refund-policy-container h4 {
  font-size: 18px;
  margin-bottom: 10px;
}

.refund-policy-container ul {
  padding-left: 20px;
}

.refund-policy-container li {
  font-size: 16px;
  margin-bottom: 8px;
}
.refund-policy-container {
    /* background-color: #007bff; */
    /* Blue background color */
    /* color: #fff; */
    /* White text color for better readability */
    padding: 20px;
    /* Add padding for spacing */
    border-radius: 8px;
    /* Optional: rounded corners for aesthetics */
    max-width: 800px;
    /* Optional: limit width for readability */
    margin: 0 auto;
    /* Optional: center the container */
}

.refund-policy-container {
    /* background-color: #007bff; */
    /* Blue background color */
    color: #000;
    /* Black text color */
    padding: 20px;
    /* Add padding for spacing */
    border-radius: 8px;
    /* Optional: rounded corners for aesthetics */
    max-width: 800px;
    /* Optional: limit width for readability */
    margin: 0 auto;
    /* Optional: center the container */
}

.refund-policy-container h2,
.refund-policy-container h3,
.refund-policy-container h4 {
    color: #000;
   
    /* Black color for headers */
}

.refund-policy-container p,
.refund-policy-container ul {
    color: #000;
    /* Black color for paragraphs and lists */
}

.refund-policy-container a {
    color: #000;
    /* Black color for links */
    text-decoration: underline;
    /* Underline links for clarity */
}


/*
------------------
Project:        Casine - Casino and gambling HTML5 Template
Version:        1.0
Last change:    11/ 05 /2020
Primary use:    Casino and gambling. 
Author:         Ingenious_team
------------------ */

@media (min-width: 1200px) and (max-width: 1299.98px) {
    .heading h2 {
        letter-spacing: 0;
    }

    .error-txt h4 {
        font-size: 110px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .side_search {
        width: 220px !important;
    }

    .full_nav .nav>li>a {
        font-size: 16px;
        padding: 9px 4px 10px;
    }

    .full_nav .nav>li:last-child {
        top: 11px;
    }

    .title {
        font-size: 75px;
        line-height: 60px;
        width: auto;
    }

    .conter-res {
        margin-top: -50px;
    }

    .banner_text h3 {
        font-size: 30px;
        padding-bottom: 30px;
    }

    .counter-left h4 {
        font-size: 36px;
        padding-top: 16px;
    }

    .counterinner ul li {
        width: 55px;
        margin: 0 4px;
        padding: 14px;
    }

    .counter-text h3 {
        font-size: 36px;
    }

    .conter-res {
        padding: 35px 10px 30px;
    }

    .control-img {
        height: auto;
        padding: 27px 15px;
    }

    .control-inner .col-lg-3 {
        padding: 0 5px;
    }

    .start-text h3 {
        font-size: 17px;
        margin-bottom: 15px;
    }

    .start-text p {
        font-size: 14px;
    }

    .casino-btn a {
        padding: 13px 21px;
    }

    .port-text-btm {
        top: 100px;
    }

    .sub-heading h3 {
        font-size: 21px;
    }

    .winner-name h5 {
        font-size: 14px;
        width: 120px;
    }

    .contact-about p,
    .winner-name p {
        font-size: 14px;
    }

    .jackpots {
        padding-bottom: 20px;
    }

    .run-txt {
        top: 60%;
    }

    .run-txt h4 {
        padding-bottom: 10px;
    }

    .run-txt p {
        padding-bottom: 12px;
    }

    .faq-inner p {
        width: auto;
        font-size: 14px;
    }

    .faq-inner h3 {
        font-size: 18px;
    }

    .faq-pad::after,
    .faq-pad::before {
        width: 720px;
    }

    .heading h3,
    .heading h2 {
        font-size: 28px;
        letter-spacing: 0;
    }

    .contact-app li a i {
        width: 77px;
        height: 77px;
        line-height: 77px;
    }

    .payments ul li {
        padding-right: 3px;
    }

    .payments ul li img {
        width: 55px;
    }

    .foot-menu ul li a {
        font-size: 16px;
    }

    .contact-app [class^="flaticon-"]:before,
    .contact-app [class*=" flaticon-"]:before,
    .contact-app [class^="flaticon-"]:after,
    .contact-app [class*=" flaticon-"]:after {
        margin-left: 0px !important;
        font-size: 34px !important;
    }

    .error {
        height: 82vh;
    }

    .error-txt h4 {
        font-size: 90px;
    }

    .error-btn {
        top: 94%;
        width: 280px;
    }

    .error-btn .casino-btn a {
        font-size: 22px;
    }

    .error-txt:before {
        top: 85%;
        left: 69%;
        width: 400px;

    }

    .location-txt {
        padding-right: 60px;
    }

    .tour-jack {
        margin-bottom: -60px;
    }

    .coundown span {
        font-size: 70px;
    }

    .contact-us .heading h2 {
        font-size: 28px;
    }

    .subscribe input {
        height: 49px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .title:before {
        left: 22px;
        top: -7px;
    }

    .in-project {
        padding-bottom: 0;
        margin-bottom: -20px;
    }

    .spin-text {
        padding-top: 0px;
    }

    .side_search {
        display: none;
    }

    .error {
        height: 82vh;
    }

    .error-txt h4 {
        font-size: 90px;
    }

    .error-btn {
        top: 94%;
        width: 280px;
    }

    .error-btn .casino-btn a {
        font-size: 22px;
    }

    .error-txt:before {
        top: 86%;
        left: 71%;
        width: 400px;
        font-size: 28px;
    }

    .full_nav .nav>li>a {
        font-size: 14px;
        padding: 10px 0px;
    }

    .full_nav .nav>li:last-child {
        right: 210px;
        top: 17px;
        height: 38px;
        width: 38px;
        padding: 7px 10px;
    }

    .full_nav .nav {
        margin-top: 10px;
        width: 595px;
        margin-left: -5px !important;
    }

    .full_nav .nav>li {
        margin: 0 4px;
    }

    .full_nav .nav>li:last-child .fa-times {
        top: 7px;
        font-size: 22px;
    }

    .nav-sign li:first-child {
        margin-right: 5px;
    }

    .nav-sign li a {
        font-size: 18px;
        padding: 9px 9px;
        width: 100px;
    }

    .title {
        font-size: 57px;
        line-height: 60px;
        width: auto;
    }

    .banner_text h3 {
        font-size: 24px;
        padding-bottom: 30px;
    }

    .counter-left h4 {
        font-size: 26px;
        padding-top: 9px;
    }

    .counterinner ul li {
        width: 39px;
        margin: 0 4px;
        padding: 7px;
    }

    .counter-text h3 {
        font-size: 30px;
    }

    .conter-res {
        margin-top: -40px;
        padding: 28px 10px;
    }

    p {
        font-size: 14px !important;
    }

    .control [class^="flaticon-"]:before,
    .control [class*=" flaticon-"]:before,
    .control [class^="flaticon-"]:after,
    .control [class*=" flaticon-"]:after {
        font-size: 52px !important;
    }

    .cont-bot {
        margin-bottom: 30px;
    }

    .start-text {
        padding-left: 0px;
        padding-right: 0;
        padding-top: 30px;
    }

    .start-text h3 {
        font-size: 17px;
        margin-bottom: 10px;
    }

    .control-inner .col-md-9 {
        padding-left: 0;
        padding-right: 0;
    }

    .project-img .nav-menu {
        font-size: 16px;
    }


    .casino-btn a {
        font-size: 17px;
        padding: 13px 18px;
    }

    .port_img .casino-btn a {
        padding: 13px 12px;
        font-size: 18px;
    }

    .port-text-btm {
        top: 106px;
    }

    .port-text-btm h3 {
        font-size: 20px;
        padding-bottom: 0;
    }

    .jack-bor {
        width: 100%;
    }

    .sub-heading h3 {
        font-size: 22px;
    }

    .run-txt h4 {
        padding-bottom: 10px;
    }

    .run-txt p {
        padding-bottom: 20px;
    }

    .running-img .casino-btn a {
        font-size: 20px;
        padding: 12px 24px;
    }

    .winner-name h5 {
        font-size: 14px;
    }

    .toun_scroll_flag {
        padding-left: 3px;
    }

    .coundown {
        margin: 0 10px;

    }

    .coundown p {
        font-size: 32px !important;
    }

    .counter-jack {
        text-align: center;
        margin-top: 50px;
        display: inline-block;
    }

    .heading h2 {
        font-size: 30px;
        letter-spacing: 0;
    }

    .faq-inner p {
        width: auto;
    }

    .faq-inner h3 {
        font-size: 18px;
    }

    .f-padding {
        padding-left: 0;
    }

    .faq-border {
        left: 51%;
    }

    .faq-form {
        margin-top: 60px;
    }

    .contact-us .heading h3,
    .contact-us .heading h2 {
        font-size: 20px;
    }

    .contact-app li a i {
        width: 70px;
        height: 70px;
        line-height: 70px;
    }

    .contact-app li {
        font-size: 16px;
    }

    .contact-app [class^="flaticon-"]:before,
    .contact-app [class*=" flaticon-"]:before,
    .contact-app [class^="flaticon-"]:after,
    .contact-app [class*=" flaticon-"]:after {
        font-size: 30px !important;
    }

    .payments ul li {
        padding-bottom: 30px;
    }

    .contact-us .heading h3 {
        padding-top: 5px;
    }

    .subscribe .border-effect2,
    .subscribe .border-effect1 {
        top: 45px;
    }

    .subscribe .heading:before,
    .subscribe .heading:after {
        width: 250px;
    }

    .subscribe .border-effect2 {
        width: 140px;
        left: 177px;
    }

    .subscribe .heading:before {
        top: 61px;
    }

    .subscribe .border-effect1 {
        width: 127px;
        left: 9px;
    }

    .sub-form .col-sm-12 {
        padding-left: 0;
    }

    .foot-menu ul li a {
        font-size: 15px;
    }

    .copy-right h6 {
        font-size: 14px;
    }

    .youtube_video {
        left: 44%;
    }

    .youtube_video a i {
        width: 70px;
        height: 70px;
        line-height: 70px;
        font-size: 22px;
        padding: 0 29px;
    }

    .foot-menu ul {
        padding-bottom: 18px;
    }

    .header_area {
        height: 86px;
    }

    .how-start {
        padding-bottom: 60px;
    }

    .location-txt {
        padding-right: 0px;
    }

    .about-video img {
        width: 100%;
    }

    .about-page .heading img {
        width: 100%;
    }

    .about-page .heading {
        padding-top: 60px;
    }

    .header-nav .navbar-brand {
        margin-right: 7px;
    }

    .start-text,
    .start-img {
        text-align: center;
        display: inline-block;
    }

    .dropdown-menu>li {
        padding: 2px 10px;
    }

    .dropdown-menu>li>a {
        font-size: 15px;
    }
}

@media only screen and (max-width: 767px) {
    .in-project {
        padding-bottom: 0;
    }

    .side_search {
        position: relative !important;
        top: 0 !important;
        margin-top: 3px !important;
    }

    .dropdown-menu {
        padding: 0px 5px;
        top: 0;
        -webkit-transition: all .4s ease;
        -ms-transition: all .4s ease;
        transition: all .4s ease;
    }

    .hidden {
        display: block;
    }

    .hidden-main {
        display: none;
    }

    p {
        font-size: 14px !important;
    }

    .faq-inner p {
        width: auto;
    }

    .main_page {
        background: #150035;
    }

    .title {
        font-size: 48px;
        line-height: 60px;
        width: auto;
    }

    .banner-center {
        top: 60%;
        left: 0%;
        width: auto;
    }

    .banner_text h3 {
        font-size: 20px;
        padding-bottom: 20px;
    }

    .control-img {
        height: 100px;
        padding: 23px 14px;
    }

    .counterinner ul li {
        width: 30px;
        margin: 0 1px;
        padding: 7px;
    }

    .counter-text h3 {
        font-size: 20px;
    }

    .counter-left h4 {
        font-size: 26px;
        padding-bottom: 20px;
        padding-top: 10px;
    }

    .counterinner ul {
        display: inline-block;
        text-align: center;
    }

    .control-inner {
        padding-left: 10px;
        padding-right: 10px;
    }

    .conter-res {
        margin-top: -40px;
        padding: 20px 10px;
    }

    .cont-bot {
        margin-bottom: 30px;
    }

    .control [class^="flaticon-"]:before,
    .control [class*=" flaticon-"]:before,
    .control [class^="flaticon-"]:after,
    .control [class*=" flaticon-"]:after {
        font-size: 55px !important;
    }

    .start-text h3 {
        margin-bottom: 3px;
        padding-top: 6px;
    }

    .control-inner {
        padding-bottom: 30px;
    }

    .how-start {
        padding-bottom: 50px;
    }

    .control-text h3 {
        font-size: 16px;
        margin-bottom: 6px;
    }

    .control-text p {
        line-height: 20px;
    }

    .free-spin {
        padding-bottom: 60px;
    }

    .heading h3,
    .heading h2 {
        font-size: 28px;
        letter-spacing: 0;
        text-align: center;
    }

    .start-text h3 {
        font-size: 16px;
        padding-top: 0px;
    }

    .project-img .nav-menu {
        font-size: 15px;
        padding: 6px 10px;
    }

    .pro-row {
        padding-bottom: 30px;
    }

    .project-img .nav-pills {
        text-align: center;
        display: contents;
    }

    .winner-name h5 {
        font-size: 13px;
    }

    .toun_scroll_flag {
        padding-left: 9px;
    }

    .sub-heading,
    .free-spin {
        text-align: center;
    }

    .running-img .img-fluid {
        margin-bottom: 30px;
        width: 100%;
    }

    .upcom-slick,
    .score-slick {
        padding-bottom: 50px;
    }

    .coundown {
        display: none;
        float: none;
        margin-left: 0px;
        padding-top: 50px;
    }

    .counter-jack {
        text-align: center;
        display: inline-block;
        padding-top: 20px;
    }

    .coundown p {
        font-size: 20px !important;
        padding-top: 5px;
    }

    .counter-jack,
    .jackpots {
        padding-bottom: 0;
    }

    .faq-inner h3 {
        font-size: 18px;
        padding-bottom: 15px;
    }

    .foot-menu {
        padding-top: 20px;
    }

    .contact-about,
    .faq-inner {
        padding-bottom: 40px;
        text-align: center;
    }

    .f-padding {
        padding-left: 0px;
    }

    .faq-pad,
    .faq-border {
        display: none;
    }

    .app-icon {
        text-align: center;
    }

    .jack-bor,
    .subscribe,
    .payments {
        text-align: center;
        width: 100%;
    }

    .payments ul {
        text-align: center;
        display: inline-block;
    }

    .payments ul li {
        padding-right: 2px;
        padding-left: 2px;
    }

    .bot-menu,
    .sub-form .col-sm-12 {
        padding-right: 0px;
        padding-left: 0px;
    }

    .sub-form {
        width: 96%;
        text-align: center;
        display: inline-block;
    }

    .foot-menu ul li a {
        font-size: 14px;
        padding: 0 4px;
    }

    .copy-right h6 {
        font-size: 14px;
    }

    .upcomg {
        padding-top: 30px;
    }

    .project-img {
        padding-bottom: 60px;
    }

    .contact-page,
    .faq,
    .project-img,
    .tour-jack {
        padding-top: 40px;
    }

    .header_area {
        background: none;
        height: 58px;
    }

    .youtube_video a i {
        top: 48%;
        left: 50%;
        width: 70px;
        height: 70px;
        line-height: 70px;
        font-size: 20px;
        padding: 0 29px;
    }

    .youtube_video {
        left: 41%;
        top: 39%;
    }

    .about-video {
        margin-bottom: 60px;
    }

    .about-page {
        padding-bottom: 30px;
    }

    .button-group .btn-filter {
        float: none;
    }

    .btn-filter {
        padding: 10px 0px;
        font-size: 18px;
        line-height: 18px;
        text-align: center;
    }

    #cssmenu>ul>li>a {
        padding: 14px;
    }

    #cssmenu ul li a {
        font-size: 14px;
    }

    #cssmenu>ul>li:hover>a,
    #cssmenu ul li.active a {
        color: #fff;
    }

    .foot-menu ul {
        display: grid;
        padding-bottom: 18px;
    }

    .port_img .casino-btn a {
        padding: 13px 18px;
        font-size: 20px;
    }

    .port-text-btm {
        top: 140px;
    }

    .tour-jack {
        margin-bottom: -50px;
    }

    .error {
        height: 83vh;
    }

    .error-txt h4 {
        font-size: 90px;
    }

    .error-btn {
        top: 94%;
        width: 280px;
    }

    .error-btn .casino-btn a {
        font-size: 22px;
    }

    .error-txt:before {
        top: 85%;
        left: 69%;
        width: 400px;

    }

    .location-txt {
        padding-right: 0px;
    }

    .contact-about .border-effect1,
    .app-icon .border-effect1,
    .subscribe .border-effect1,
    .payments .border-effect1 {
        width: 100px;
        left: 0;
    }

    .border-effect1 {
        width: 99px;
        left: 80px;
    }

    .border-effect2 {
        width: 96px;
        right: 60px
    }

    .contact-about .border-effect2,
    .app-icon .border-effect2,
    .subscribe .border-effect2,
    .payments .border-effect2 {
        width: 95px;
        left: 220px;
    }

    .heading:before,
    .heading:after,
    .subscribe .heading:before,
    .subscribe .heading:after,
    .contact-about .heading:before,
    .contact-about .heading:after,
    .payments .heading:before,
    .payments .heading:after {
        width: 100%;
        left: 50%;
    }

    .title:before {
        position: absolute;
        content: 'Online Casino';
        text-shadow: 21px 13px 2px #2c0b49;
        filter: drop-shadow(0 0 40px #68248c);
        left: 48%;
        width: 250px;
        top: -8px;
        transform: translateX(-50%);
        z-index: -99;
    }

    .control-pad {
        display: none;
    }

    .control {
        padding-bottom: 40px;
    }
}

@media (min-width: 381px) and (max-width: 575px) {
    .btn-filter {
        padding: 10px 0px;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
    }

    .contact-app li a i {
        width: 78px;
        height: 78px;
        line-height: 78px;
    }

    .contact-app [class^="flaticon-"]:before,
    .contact-app [class*=" flaticon-"]:before,
    .contact-app [class^="flaticon-"]:after,
    .contact-app [class*=" flaticon-"]:after {
        margin-left: 0px !important;
        font-size: 34px !important;
    }

    .title:before {
        left: 46%;
    }
}

@media only screen and (max-width: 380.99px) {

    .winner-name p,
    .winner-name h5 {
        font-size: 12px !important;
    }

    .section {
        padding-top: 60px;
    }

    .control-inner .col-8 {
        padding-left: 0;
    }

    .control-inner {
        padding-bottom: 5px;
    }

    .control-inner {
        margin-bottom: 30px;
    }

    .btn-filter {

        padding: 10px 0px;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
    }

    .foot-menu ul li a {
        font-size: 13px;
        padding: 0 4px;
    }

    .copy-right h6 {
        font-size: 13px;
    }

    .contact-app li a i {
        width: 70px;
        height: 70px;
        line-height: 70px;
    }

    .contact-app [class^="flaticon-"]:before,
    .contact-app [class*=" flaticon-"]:before,
    .contact-app [class^="flaticon-"]:after,
    .contact-app [class*=" flaticon-"]:after {
        margin-left: 0px !important;
        font-size: 32px !important;
    }

    #back-top-btn {
        width: 40px;
        height: 40px;
        padding: 6px;
    }

    .contact-page .heading:after,
    .heading:before,
    .heading:after,
    .subscribe .heading:before,
    .subscribe .heading:after,
    .contact-about .heading:before,
    .contact-about .heading:after,
    .payments .heading:before,
    .payments .heading:after {
        width: 260px;
        left: 50%;
    }

    .title:before {
        width: 250px;
        left: 44%;
    }

    .payments ul li {
        padding-right: 0;
        padding-left: 0;
        margin: 0 -1px;
    }

    .payments ul li img {
        width: 86%;
    }

    .error-txt h4 {
        font-size: 75px;
    }

    .error-txt:before {
        font-size: 26px;
        top: 85%;
        left: 65%;
        width: 300px;

    }

}


/* .btn-4in{
    letter-spacing: 1px;
        line-height: 24px;
        color: #fefefe;
        font-weight: 400;
        font-family: "Anton";
        padding: 13px 15px;
        border-radius: 35px;
        text-transform: capitalize;
        width: 130px;
        -webkit-transition: all ease 0.5s;
        -moz-transition: all ease 0.5s;
        -ms-transition: all ease 0.5s;
        -o-transition: all ease 0.5s;
        transition: all ease 0.5s;
        box-shadow: 0px 0px 2px 2px rgba(247, 172, 4, 0.8);
        text-shadow: 5px 4px 0px #efa500;
        background: linear-gradient(to bottom, #ffd679 30%, #f7ac03 70%);
        filter: drop-shadow(0 0px 20px rgba(101, 40, 193, 0.5));
} */

@media (min-width: 766px) {
    .login_menu-1 {
        display: none;
    }
}

.refund-policy-container {
    padding: 20px;
    margin: 20px auto;
    max-width: 800px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* background-color: #fff; */
}

.refund-policy-container h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.refund-policy-container p {
    font-size: 16px;
    line-height: 1.6;
}

.refund-policy-container h3 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.refund-policy-container h4 {
    font-size: 18px;
    margin-bottom: 10px;
}

.refund-policy-container ul {
    padding-left: 20px;
}

.refund-policy-container li {
    font-size: 16px;
    margin-bottom: 8px;
}

.refund-policy-container {
    /* background-color: #007bff; */
    /* Blue background color */
    /* color: #fff; */
    /* White text color for better readability */
    padding: 20px;
    /* Add padding for spacing */
    border-radius: 8px;
    /* Optional: rounded corners for aesthetics */
    max-width: 800px;
    /* Optional: limit width for readability */
    margin: 0 auto;
    /* Optional: center the container */
}

.refund-policy-container {
    /* background-color: #007bff; */
    /* Blue background color */
    color: #000;
    /* Black text color */
    padding: 20px;
    /* Add padding for spacing */
    border-radius: 8px;
    /* Optional: rounded corners for aesthetics */
    max-width: 800px;
    /* Optional: limit width for readability */
    margin: 0 auto;
    /* Optional: center the container */
}

.refund-policy-container h2,
.refund-policy-container h3,
.refund-policy-container h4 {
    color: #000;

    /* Black color for headers */
}

.refund-policy-container p,
.refund-policy-container ul {
    color: #000;
    /* Black color for paragraphs and lists */
}

.refund-policy-container a {
    color: #000;
    /* Black color for links */
    text-decoration: underline;
    /* Underline links for clarity */
}


/*
------------------
Project:        Casine - Casino and gambling HTML5 Template
Version:        1.0
Last change:    11/ 05 /2020
Primary use:    Casino and gambling. 
Author:         Ingenious_team
------------------ */

/* Initial header style with transparent background */
.header-transparent #nav-part {
    background-color: transparent;
    transition: background-color 0.3s ease, top 0.3s ease;
}

/* Header style when scrolled, with a solid background */
.header-scrolled #nav-part {
    background-color: #200449;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, top 0.3s ease;
}

.download-button-container {
    height: 80px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: #200449;;
    box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    display: flex;
    justify-content: center;
}

/* Apply styles only for screens with width 500px or less */
@media (max-width: 766px) {
    .download-button-container {
        display: flex;
        /* Ensure the container is displayed */
    }

    .download-button {
text-transform: capitalize;
    width: 130px;
    -webkit-transition: all ease 0.5s;
    -moz-transition: all ease 0.5s;
    -ms-transition: all ease 0.5s;
    -o-transition: all ease 0.5s;
    transition: all ease 0.5s;
    box-shadow: 0px 0px 2px 2px rgba(247, 172, 4, 0.8);
    text-shadow: 5px 4px 0px #efa500;
    background: linear-gradient(to bottom, #ffd679 30%, #f7ac03 70%);
    filter: drop-shadow(0 0px 20px rgba(101, 40, 193, 0.5));
            color: white;
        font-weight: 700;
        font-style: italic;
        letter-spacing: -0.32px;
        font-size: 23px;
        /* Adjusted for smaller screens */
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 95%;
        max-width: 400px;
    }

    .android-icon,
    .download-icon {
        height: 20px;
        width: 20px;
        margin-right: 10px;
        margin-left: 10px;
    }
}

/* Hide the button container when screen width exceeds 500px */
@media (min-width: 766px) {
    .download-button-container {
        display: none;
    }
}


.contact-info {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    /* Adjust gap between columns as needed */
}

.contact-item {
    flex: 1 1 calc(50% - 20px);
    /* 2 columns with a gap of 20px */
    box-sizing: border-box;
}

@media (max-width: 768px) {
    .contact-item {
        flex: 1 1 100%;
        /* Stacks items in a single column on smaller screens */
    }
}